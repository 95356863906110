import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import { gql } from 'graphql-request';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type NotificationPreferenceInput = {
  categoryKey: Scalars['String'];
  enabled: Scalars['Boolean'];
};

export type PartnerUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  partnerIdentifier: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  regionIdentifier?: InputMaybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
};

export type ReferralState =
  | 'LEAD_DENIED'
  | 'LEAD_QUALIFIED'
  | 'LEAD_SELLER_FLOW_INCOMPLETE'
  | 'OFFER_ACCEPTED'
  | 'OFFER_ASSESSMENTS_COMPLETED'
  | 'OFFER_CLOSED'
  | 'OFFER_CONTINGENCIES_RELEASED'
  | 'OFFER_EXPIRED'
  | 'OFFER_EXPIRING_SOON'
  | 'OFFER_IN_CONTRACT'
  | 'OFFER_SENT'
  | 'OFFER_VIEWED'
  | 'OFFER_WITHDRAWN'
  | 'UNKNOWN';

export type RefreshOfferInput = {
  sellerInputId: Scalars['String'];
};

export type SearchReferralsInput = {
  cursor?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  state?: InputMaybe<ReferralState>;
};

export type SlackChannel =
  | 'SIGN_UP';

export type SlackMessageField = {
  title: Scalars['String'];
  value: Scalars['String'];
};

export type TourRequestState =
  | 'CANCELED'
  | 'COMPLETE'
  | 'NO_SHOW'
  | 'REQUESTED'
  | 'SCHEDULED';

export type UpdateUserNotificationPreferencesInput = {
  notificationPreferences: Array<NotificationPreferenceInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ValidateEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type ValidateEmailQuery = { __typename?: 'Query', validateEmail: { __typename?: 'ValidateEmailResponse', isValid: boolean, normalizedEmail: string } };


export const ValidateEmailDocument = gql`
    query ValidateEmail($email: String!) {
  validateEmail(email: $email) {
    isValid
    normalizedEmail
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    ValidateEmail(variables: ValidateEmailQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ValidateEmailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ValidateEmailQuery>(ValidateEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ValidateEmail', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;