// storybook-check-ignore
import * as Cookies from 'js-cookie';

export const normalize = (input: string): string => {
  return input
    .split(/\s+/)
    .map((s) => s.toLowerCase())
    .join('-');
};

export function getAnonymousId(): string | undefined {
  const anonymousId = Cookies.get('ajs_anonymous_id');
  if (!anonymousId) {
    return undefined;
  }
  return anonymousId;
}

export const setCookie = (key: string, value: string, expires: number) => {
  // For real staging and production, we need the cookie to be accessible
  // across all subdomains
  // i.e. ".domain.com" cookies are available on all subdomains, vs "www.domain.com"
  // which is only available on same-origin
  // https://www.mxsasha.eu/blog/2014/03/04/definitive-guide-to-cookie-domains/
  let cookieDomain = window.location.hostname;
  if (cookieDomain.indexOf('opendoor.com') !== -1) {
    cookieDomain = '.opendoor.com';
  } else if (window.location.hostname.indexOf('simplersell.com') !== -1) {
    cookieDomain = '.simplersell.com';
  }
  // expires is in days
  Cookies.set(key, value, { expires, domain: cookieDomain });
};
