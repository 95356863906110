import { useEffect, useState } from 'react';

import { useAuth } from '@opendoor/auth-fe';

import {
  GetSeveralFeatureFlagsRequestQueryVariables,
  OdProtosCustomerV1Services_CustomerRequests_Update_ParamsInput,
} from '__generated__/athena';

import { getAthenaClient, getFeatureFlag, getSeveralFeatureFlags } from '../api';
import { Customer, UseCurrentCustomer } from './types';

/**
 * Checks for a localstorage feature flag override
 * Used for development/QA only!
 *
 * force enabled -> window.localStorage.setItem('opendoor.reception.exclusive-offers', true)
 * force disabled -> window.localStorage.setItem('opendoor.reception.exclusive-offers', false)
 */
export const getLocalFeatureFlagOverrides = (featureFlag: string) => {
  try {
    // Ignore override check on the server
    return (
      typeof window !== 'undefined' &&
      window.localStorage.getItem(`opendoor.cosmos.${featureFlag}`) === 'true'
    );
  } catch {
    return false;
  }
};

// Add Amplitude Feature Flags to this list to keep track of FFs in Cosmos: 'new-hub-nav' = 'new-hub-nav',
export enum AMPLITUDE_FEATURE_FLAGS {
  exclusivesPdpRedesign = 'exclusives-23-10-pdp-redesign',
  exclusivesProductComprehensionRedesign = 'exclusives-24-02-product-comprehension-redesign',
}

// This pulls back an object of feature flags from amplitude - brings back to the default value if not found
export const useGetSeveralFeatureFlag = (
  names: Record<AMPLITUDE_FEATURE_FLAGS, { defaultValue: string }>,
  customerUUID?: string,
  options: { isLoading?: boolean; market?: string } = {},
) => {
  const [severalFeatureFlagsEnabled, setSeveralFeatureFlagsEnabled] = useState<
    Partial<Record<AMPLITUDE_FEATURE_FLAGS, string>>
  >({});

  useEffect(() => {
    getSeveralFeatureFlags(names, customerUUID, options).then(setSeveralFeatureFlagsEnabled);
  }, []);

  return severalFeatureFlagsEnabled;
};

export const useGetFeatureFlag = (
  name: string,
  customerUUID?: string,
  options: {
    isLoading?: boolean;
    autoImpression?: boolean;
    defaultValue?: string;
    props?: GetSeveralFeatureFlagsRequestQueryVariables['props'];
  } = {},
) => {
  const [featureFlag, setFeatureFlag] = useState<string>(options.defaultValue || '');

  useEffect(() => {
    getFeatureFlag(name, customerUUID, options).then(setFeatureFlag);
  }, []);

  return featureFlag;
};

/**
 * Gets the customer data for the currently logged in user and provides a method to update the current cutomer's data.
 */
export const useCurrentCustomer: UseCurrentCustomer = (options = {}) => {
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const {
    authentication: { state: authState },
  } = useAuth();

  useEffect(() => {
    if (authState !== 'authenticated') {
      setCustomer(null);
      setIsLoading(false);
      return;
    }

    const getCustomer = async () => {
      setIsLoading(true);
      try {
        const customerResp = await getAthenaClient().GetCurrentCustomer();
        setCustomer(
          (customerResp.customerService.getCurrentCustomer?.customer as Customer) || null,
        );
      } catch (e) {
        setCustomer(null);
        if (!options.onGetError) throw e;

        options.onGetError(e);
      } finally {
        setIsLoading(false);
      }
    };

    getCustomer();
  }, [authState]);

  const update = async (params: OdProtosCustomerV1Services_CustomerRequests_Update_ParamsInput) => {
    if (!customer) return;

    setIsUpdating(true);
    try {
      const resp = await getAthenaClient().UpdateCurrentCustomer({
        customerUpdates: params,
      });

      const updatedCustomer = resp.customerService.updateCurrent?.updatedCustomer as Customer;

      setCustomer(updatedCustomer);
      return updatedCustomer;
    } catch (e) {
      if (!options.onUpdateError) throw e;

      options.onUpdateError(e);
      return;
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    customer,
    isLoading,
    isUpdating,
    isUserAuthenticated: authState === 'authenticated',
    update,
  };
};
